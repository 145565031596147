import React from "react"
import styled from "styled-components"
import HorizontalBlock from "../components/HorizontalBlock"
import { injectIntl } from "gatsby-plugin-intl"
import SimpleHorizontalBlock from "../components/SimpleHorizontalBlock"

const Seminario = ({ data, featured, intl }) => {
  const { edges: dataQuery } = data.allContentfulEvent
  const { edges: featuredEvent } = data.featuredEvent

  return (
    <>
      <SectionEvent>
        <EventCenter>
          {
            /* // If an Event is featured then display one
            // Otherwise display all of them */
            featured == true
              ? featuredEvent.map((event, index) => {
                  const eventInfo = event.node
                  return (
                    <>
                      <HorizontalBlock key={eventInfo.id} {...eventInfo} />
                    </>
                  )
                })
              : dataQuery.map((event, index) => {
                  let eventInfo = event.node
                  return (
                    <>
                      <>
                        <SimpleHorizontalBlock key={eventInfo.id} {...eventInfo} />
                      </>
                    </>
                  )
                })
          }
        </EventCenter>
      </SectionEvent>
    </>
  )
}

const SectionEvent = styled.section`
  padding-bottom: 2rem;
  padding-top: 5rem;
`
const EventCenter = styled.section`
  width: 80vw;
  left: 20%;
  max-width: 1170px;
  margin: 0 auto;
  align-items: center;
`
const Button = styled.button`
  display: block;
  width: 14rem;
  height: 3.1rem;
  text-align: center;
  margin: 0 auto;
  margin: 3rem auto 0;
`

export default injectIntl(Seminario)
