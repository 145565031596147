import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const SimpleHorizontalBlock = ({ description, title, image }) => {
  return (
    <Section>
      <ImageParent>
        <ImageChild>{<EventImage fluid={image.fluid} />}</ImageChild>
      </ImageParent>
      <TextBlock>
        <EventTitle>{title}</EventTitle>
        <EventDescription>{description.description}</EventDescription>
      </TextBlock>
    </Section>
  )
}

export const EventTitle = styled.h3`
  font-family: ${({ theme }) => theme.secondaryFont};
  margin-right: 0.5rem;
`

export const ImageChild = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  /* fallback color */
  &::before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const ImageParent = styled.div`
  border-top-left-radius: ${({ theme }) => theme.radius};
  border-top-right-radius: ${({ theme }) => theme.radius};
  height: 19rem;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: inline-block;
  :hover ${ImageChild} {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  :focus ${ImageChild} {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  :hover ${ImageChild}:before {
    display: block;
  }
  :focus ${ImageChild}:before {
    display: block;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    margin: 0px 0px;
  }
  @media screen and (min-width: 576px) {
    height: 19rem;
  }
  @media screen and (min-width: 768px) {
    /* Image Height */
    height: 30rem;
  }
  @media screen and (min-width: 992px) {
    grid-column: 1 / 5;
    grid-row: 1 / 1;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius};
    box-shadow: ${({ theme }) => theme.darkShadow};
  }
`

export const Section = styled.section`
  display: grid;
  margin-bottom: 5rem;
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }
`

// Animation for the Image. when :hover
export const EventImage = styled(Image)`
  ${Section}:hover & ::after {
    opacity: 0;
  }
  border-top-left-radius: ${({ theme }) => theme.radius};
  border-top-right-radius: ${({ theme }) => theme.radius};
  height: 19rem;
  z-index: 1;
  & ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(
      to bottom left,
      ${({ theme }) => theme.colorPrimary5},
      #222
    );
    transition: ${({ theme }) => theme.transition};
  }
  @media screen and (min-width: 576px) {
    height: 19rem;
    /* position: inherit */
  }
  @media screen and (min-width: 768px) {
    height: 100%;
    /* position: inherit */
  }
  @media screen and (min-width: 992px) {
    grid-column: 1 / 5;
    grid-row: 1 / 1;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius};
    /*Todo: need to double check this one */
    box-shadow: ${({ theme }) => theme.darkShadow};
  }
`

export const EventDescription = styled.p`
  margin-bottom: 1.25rem;
  word-spacing: 5px;
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.colorGrey11};
`

export const TextBlock = styled.section`
  background: ${({ theme }) => theme.colorGrey10};
  border-bottom-left-radius: ${({ theme }) => theme.radius};
  border-bottom-right-radius: ${({ theme }) => theme.radius};
  padding: 2rem 2rem;
  h3 {
    font-weight: 500;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  box-shadow: ${({ theme }) => theme.darkShadow};
  @media screen and (min-width: 992px) {
    padding: 2.5rem 2.5rem;
    background: ${({ theme }) => theme.colorGrey10};
    border-radius: ${({ theme }) => theme.radius};
    box-shadow: ${({ theme }) => theme.darkShadow};
    z-index: 1;
    grid-column: 5 /13;
    grid-row: 1 / 1;
  }
`

export const ProjLinks = styled.section``

export default injectIntl(SimpleHorizontalBlock)
