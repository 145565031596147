import React from "react"
import Image from "gatsby-image"
import {
  FaMapMarker,
  FaCalendar,
} from "react-icons/fa"
import styled from "styled-components"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

let dateAndTime = <FormattedMessage id="dateTime" />
let addressChurch = <FormattedMessage id="addressChurch" />

const HorizontalBlock = ({
  description,
  address,
  title,
  image,
  date,
  intl,
}) => {
  return (
    <Section>
      <ImageParent>
        <ImageChild>{<EventImage fluid={image.fluid} />}</ImageChild>
      </ImageParent>
      <TextBlock>
        <ProjTitle>{title}</ProjTitle>
        <ProjDescription>{description.description}</ProjDescription>
        <IconLink>
          <FaCalendar></FaCalendar>
        </IconLink>
        <DateTitle>{dateAndTime} </DateTitle>
        <DateAndTime>{date}</DateAndTime>
        <ProjLinks>
          <Div>
            <IconLink target="_blank" href={"www.github.com"}>
              <FaMapMarker />
            </IconLink>
            <TextWrapper>
              <AddressTitle>{addressChurch}</AddressTitle>
              <Address> {address} </Address>
            </TextWrapper>
          </Div>
        </ProjLinks>
      </TextBlock>
    </Section>
  )
}

export const IconLink = styled.a`
  color: ${({ theme }) => theme.colorPrimary5};
  font-size: 1rem;
  margin-right: 0.5rem;
  transition: ${({ theme }) => theme.transition};
  :hover {
    color: ${({ theme }) => theme.text};
  }
`

const TextWrapper = styled.div`
  display: inline;
`
const AddressTitle = styled.h4`
  display: inline-block;
  font-size: 1rem;
  color: ${({ theme }) => theme.colorPrimary5};
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
`

export const ProjTitle = styled.h3`
  font-family: ${({ theme }) => theme.secondaryFont};
  margin-right: 0.5rem;
`

export const DateDiv = styled.div``
export const DateAndTime = styled.p`
  display: inline-block;
  font-size: 1rem;
  /* color: ${({ theme }) => theme.colorPrimary5}; */
  margin-bottom: 0.25rem;
`

export const DateTitle = styled.h4`
  display: inline-block;
  font-size: 1rem;
  color: ${({ theme }) => theme.colorPrimary5};
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
`
const Address = styled.p`
  display: inline-block;
`

const Div = styled.div`
  display: inline;
`

export const ImageChild = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  /* fallback color */
  &::before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const ImageParent = styled.div`
  border-top-left-radius: ${({ theme }) => theme.radius};
  border-top-right-radius: ${({ theme }) => theme.radius};
  height: 19rem;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: inline-block;
  :hover ${ImageChild} {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  :focus ${ImageChild} {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  :hover ${ImageChild}:before {
    display: block;
  }
  :focus ${ImageChild}:before {
    display: block;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    margin: 0px 0px;
  }
  @media screen and (min-width: 576px) {
    height: 19rem;
  }
  @media screen and (min-width: 768px) {
    height: 30rem;
  }
  @media screen and (min-width: 992px) {
    grid-column: 1 / 5;
    grid-row: 1 / 1;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius};
    box-shadow: ${({ theme }) => theme.darkShadow};
  }
`

export const Section = styled.section`
  display: grid;
  margin-bottom: 5rem;
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }
`

// Animation for the Image. when :hover
export const EventImage = styled(Image)`
  ${Section}:hover & ::after {
    opacity: 0;
  }
  border-top-left-radius: ${({ theme }) => theme.radius};
  border-top-right-radius: ${({ theme }) => theme.radius};
  height: 19rem;
  z-index: 1;
  & ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(
      to bottom left,
      ${({ theme }) => theme.colorPrimary5},
      #222
    );
    transition: ${({ theme }) => theme.transition};
  }
  @media screen and (min-width: 576px) {
    height: 19rem;
  }
  @media screen and (min-width: 768px) {
    height: 100%;
  }
  @media screen and (min-width: 992px) {
    grid-column: 1 / 5;
    grid-row: 1 / 1;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.radius};
    /*Todo: need to double check this one */
    box-shadow: ${({ theme }) => theme.darkShadow};
  }
`

export const ProjDescription = styled.p`
  margin-bottom: 1.25rem;
  word-spacing: 5px;
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.colorGrey11};
`

export const TextBlock = styled.section`
  background: ${({ theme }) => theme.colorGrey10};
  border-bottom-left-radius: ${({ theme }) => theme.radius};
  border-bottom-right-radius: ${({ theme }) => theme.radius};
  padding: 2rem 2rem;
  h3 {
    font-weight: 500;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  box-shadow: ${({ theme }) => theme.darkShadow};
  @media screen and (min-width: 992px) {
    padding: 2.5rem 2.5rem;
    background: ${({ theme }) => theme.colorGrey10};
    border-radius: ${({ theme }) => theme.radius};
    box-shadow: ${({ theme }) => theme.darkShadow};
    z-index: 1;
    grid-column: 5 /13;
    grid-row: 1 / 1;
  }
`

export const ProjLinks = styled.section``

export default injectIntl(HorizontalBlock)
