import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import Hero from "../components/HeroTwoCenter"
import Seminarios from "../constants/Seminarios"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const seminarios = ({ data }) => {
  let titleSeminarios
  const { node_locale } = data.allContentfulSeminarios.edges[0].node
  node_locale === "en"
    ? (titleSeminarios = "Seminars")
    : (titleSeminarios = "Seminarios")

  const subtitle = (
    <FormattedMessage id="subtitulo_seminario"></FormattedMessage>
  )

  const {
    content,
  } = data.allContentfulSeminarios.edges[0].node.contenido.childMarkdownRemark.internal

  return (
    <>
      <Layout>
        <Hero
          content={titleSeminarios}
          image={data.file}
        ></Hero>
        <Div>
          <Wrapper>
            <TitleWrapper>
              <Title>
                <FormattedMessage id="proximoSem" />
              </Title>
            </TitleWrapper>
            <Seminarios data={data} featured={true} />
            <Title>
              <FormattedMessage id="pastSem" />
            </Title>
            <HorizontalEventDiv>
              <Seminarios data={data} featured={false}></Seminarios>
            </HorizontalEventDiv>
          </Wrapper>
        </Div>
      </Layout>
    </>
  )
}

export default injectIntl(seminarios)

const TitleWrapper = styled.div`
  display: block;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
`
const Title = styled.h2`

  display: inline-block;
  vertical-align: middle;
  z-index: 2;
`

const Div = styled.div`
  display: flex;
  height: 100%;
  background: ${({ theme }) => theme.colorGrey10};
`

const Wrapper = styled.section`
  background: ${({ theme }) => theme.colorGrey10};
  width: 90vw;
  margin: 4rem auto;
`

const HorizontalEventDiv = styled.div``

export const query = graphql`
  query($locale: String) {
    allContentfulSeminarios(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          id
          contenido {
            childMarkdownRemark {
              internal {
                content
              }
            }
          }
          node_locale
        }
      }
    }

    file(relativePath: { eq: "hands.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allContentfulEvent(
      skip: 2
      sort: { fields: date, order: DESC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          image {
            fluid {
              src
            }
            title
          }
          title
          description {
            description
          }
          id
          date
          address
          node_locale
          featured
        }
      }
    }

    featuredEvent: allContentfulEvent(
      sort: { fields: date, order: DESC }
      filter: { featured: { eq: true }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          image {
            fluid {
              src
            }
            title
          }
          title
          description {
            description
          }
          id
          date
          address
          node_locale
          featured
        }
      }
    }
  }
`
